
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Carousel extends Vue {
  showLeftButton = false;
  showRightButton = false;

  protected mounted() {
    this.handleScroll();
  }

  handleScroll() {
    const scrollableElement = (this.$refs['scrollable'] as HTMLDivElement);

    this.showLeftButton = scrollableElement.scrollLeft > 0;
    this.showRightButton = scrollableElement.scrollLeft < scrollableElement.scrollWidth - scrollableElement.offsetWidth;
  }

  nextItem() {
    const scrollableElement = (this.$refs['scrollable'] as HTMLDivElement);
    const contentElement = (this.$refs['content'] as HTMLDivElement);

    const currentRightScrollEdge = scrollableElement.scrollLeft + scrollableElement.offsetWidth;

    for (let index = 0; index < contentElement.children.length; index++) {
      const element = (contentElement.children.item(index) as HTMLDivElement);
      const elementRightEdge = element.offsetLeft + element.offsetWidth;

      if (elementRightEdge > currentRightScrollEdge) {
        scrollableElement.scrollTo({
          left: elementRightEdge - scrollableElement.offsetWidth,
          behavior: 'smooth'
        });
        break;
      }
    }
  }

  previousItem() {
    const scrollableElement = (this.$refs['scrollable'] as HTMLDivElement);
    const contentElement = (this.$refs['content'] as HTMLDivElement);

    const currentLeftScrollEdge = scrollableElement.scrollLeft;

    for (let index = contentElement.children.length - 1; index >= 0; index--) {
      const element = (contentElement.children.item(index) as HTMLDivElement);
      const elementLeftEdge = element.offsetLeft;

      if (elementLeftEdge < currentLeftScrollEdge) {
        scrollableElement.scrollTo({
          left: elementLeftEdge,
          behavior: 'smooth'
        });
        break;
      }
    }
  }
}
