
import {Component, Vue} from 'vue-property-decorator';

// Config
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import Carousel from '../components/carousel.component.vue';
import PageHeader from '../components/page-header.component.vue';

@Component({
  components: {
    Carousel,
    PageHeader
  }
})
export default class AboutView extends Vue {
  teamMembers = [
    {
      imgUrl: 'https://www.dailymoss.com/wp-content/uploads/2019/08/funny-profile-pic59.jpg',
      name: 'Ana Fernandes',
      role: 'CEO'
    },
    {
      imgUrl: 'https://www.dailymoss.com/wp-content/uploads/2019/08/funny-profile-pic59.jpg',
      name: 'Gonçalo Martins',
      role: 'CMO'
    },
    {
      imgUrl: 'https://www.dailymoss.com/wp-content/uploads/2019/08/funny-profile-pic59.jpg',
      name: 'João Paulo Fernandes',
      role: 'CFO'
    }
  ]

  protected created() {
    track(TrackingEventType.ViewContent, {
      content_category: RouteNames.About
    });
  }
}
